.card-detail {
  p {
    display: flex;
    flex-direction: row;
  }

  i {
    display: flex;
    justify-content: center;
    width: 30px;
    margin-right: 10px;
  }
}

.detail-table {
  margin-top: 20px;
}

.button-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;

  button {
    margin: 10px;
  }
}

.eye-toggle-container {
  padding: 8px;
}

.v-centered-row {
  display: flex;
  align-items: center;
}

.space-between {
  display: flex;
  justify-content: space-between;
}

.justify-end {
  display: flex;
  justify-content: flex-end;
}