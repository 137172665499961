.cards-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
  .card-buttons-container {
    display: flex;
    justify-content: flex-end;
  }
  .mat-expansion-panel-header-description {
    display: flex;
    justify-content: flex-end;
    button {
      margin-right: 20px;
    }
  }
  .mat-expansion-panel-header-title {
    font-size: 18px;
    font-weight: bold;
  }
  .card-header {
    font-size: 18px;
    padding: 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .card-header-actions {
      button:nth-child(1) {
        width: 350px;
      }
    }
  }
}
