$mainTextColor: #333333;
$mainBackgroundColor: #ffffff;
$mainBackgroundSecondaryColor: $mainTextColor;
$disabledColor: #dddddd;

/* Primary and Secondary accent color for your app */
$primaryColor: #40034e;
$primaryTextColor: #ffffff;
$secondaryColor: #8d37ee;
$tertiaryColor: #ff2782;
$gradient: linear-gradient(0deg, rgba(64,3,78,1) 0%, rgba(255,39,130,1) 100%);
$gradient-variant: linear-gradient(0deg, rgba(64,3,78,1) 0%, rgba(141,55,238,1) 100%);

/* =============================================== */

$infoTextColor: #0072bb;
$infoBackgroundColor: #07CCD6;

$successTextColor: #006e57;
$successBackgroundColor: #a9e2da;

$warningTextColor: #a99700;
$warningBackgroundColor: #f6f2bd;

$errorTextColor: #ca1829;
$errorBackgroundColor: #ca1829;
$errorFormTextColor: #f44336
