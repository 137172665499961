.create-modify-container {
  padding: 20px;
  width: 800px;
  height: 90vh;
  overflow: auto;
  .create-modify-button {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    button {
      margin: 0 5px;
    }
  }
}

form {
  overflow-y: hidden;
}

.col-2 {
  display: flex;
  flex-direction: row;
  align-content: center;
  justify-content: space-between;

  .sided {
    width: 48%;

    &:first-child {
      margin: 0 1% 0 0;
    }
    &:last-child {
      margin: 0 0 0 1%;
    }
  }
}

.col-3 {
  display: flex;
  flex-direction: row;
  align-content: center;
  justify-content: space-between;

  .sided {
    width: 30%;

    &:first-child {
      margin: 0 1% 0 0;
    }
    &:last-child {
      margin: 0 0 0 1%;
    }
  }
}

.col-4 {
  display: flex;
  flex-direction: row;
  align-content: center;
  justify-content: space-between;

  .sided {
    width: 23%;
    margin: 0 1% 0 1%;

    &:first-child {
      margin: 0 1% 0 0;
    }
    &:last-child {
      margin: 0 0 0 1%;
    }
  }
}

.col-50-20-30 {
  display: flex;
  flex-direction: row;
  align-content: center;
  justify-content: space-between;

  .sided {
    &:first-child {
      width: 48%;
      margin: 0 2% 0 0;
    }
    &:nth-child(2) {
      width: 16%;
      margin: 0 2% 0 2%;
    }
    &:last-child {
      width: 28%;
      margin: 0 0 0 2%;
    }
  }
}

.col-40-30-30 {
  display: flex;
  flex-direction: row;
  align-content: center;
  justify-content: space-between;

  .sided {
    &:first-child {
      width: 38%;
      margin: 0 2% 0 0;
    }
    &:nth-child(2) {
      width: 26%;
      margin: 0 2% 0 2%;
    }
    &:last-child {
      width: 28%;
      margin: 0 0 0 2%;
    }
  }
}

::ng-deep {
  .readonly .mdc-text-field--filled:not(.mdc-text-field--disabled) {
    background-color: #ccc !important;
  }
}
