*, *:before, *:after {
  box-sizing: border-box;
}

html, body, div, span, object, iframe, figure, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, code, em, img, small, strike, strong, sub, sup, tt, b, u, i, ol, ul, li, fieldset, form, label, table, caption, tbody, tfoot, thead, tr, th, td, main, canvas, embed, footer, header, nav, section, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  text-size-adjust: none;
}

footer, header, nav, section, main {
  display: block;
}

body {
  line-height: 1;
}

ol, ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after, q:before, q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

input {
  -webkit-appearance: none;
  border-radius: 0;
}

/* Primary and Secondary accent color for your app */
/* =============================================== */
/* Primary and Secondary accent color for your app */
/* =============================================== */
.auth-form {
  min-width: 320px;
  max-width: 320px;
  width: 100%;
  display: flex;
  flex-direction: column;
}

mat-form-field {
  width: 100%;
  margin: 10px 0;
}

.mat-mdc-form-field-hint-wrapper, .mat-mdc-form-field-error-wrapper {
  position: relative !important;
  top: -15px !important;
  display: block !important;
  padding: 0 !important;
}

.cards-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.cards-container .card-buttons-container {
  display: flex;
  justify-content: flex-end;
}
.cards-container .mat-expansion-panel-header-description {
  display: flex;
  justify-content: flex-end;
}
.cards-container .mat-expansion-panel-header-description button {
  margin-right: 20px;
}
.cards-container .mat-expansion-panel-header-title {
  font-size: 18px;
  font-weight: bold;
}
.cards-container .card-header {
  font-size: 18px;
  padding: 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.cards-container .card-header .card-header-actions button:nth-child(1) {
  width: 350px;
}

/* Primary and Secondary accent color for your app */
/* =============================================== */
/* Primary and Secondary accent color for your app */
/* =============================================== */
.auth-form {
  min-width: 320px;
  max-width: 320px;
  width: 100%;
  display: flex;
  flex-direction: column;
}

mat-form-field {
  width: 100%;
  margin: 10px 0;
}

.mat-mdc-form-field-hint-wrapper, .mat-mdc-form-field-error-wrapper {
  position: relative !important;
  top: -15px !important;
  display: block !important;
  padding: 0 !important;
}

html, body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Roboto, "Montserrat", sans-serif;
}

.spacer {
  display: flex;
  flex-grow: 1;
}

h1, h2, h3, h4, h5 {
  color: #333333;
  display: block;
}

h1 {
  font-size: 50px;
}

h2 {
  font-size: 35px;
}

h3 {
  font-size: 24.5px;
}

h4 {
  font-size: 17.15px;
}

h5 {
  font-size: 16px;
}

p {
  color: #333333;
}

span {
  display: inline-block;
}

small {
  display: inline-block;
  color: #333333;
  font-size: 12.8px;
}

a {
  display: inline-block;
  color: #40034e;
  text-decoration: none;
  opacity: 1;
  transition: opacity 0.3s ease-in;
  cursor: pointer;
}
a :hover {
  opacity: 0.7;
}
a :visited {
  color: #ff2782;
}

code {
  background: #333333;
  font-family: "monospace";
  color: #333333;
  padding: 10px;
}

ul {
  display: inline-block;
  list-style: none;
  margin: 0;
  padding: 0;
}
ul li {
  margin: 10px 0;
}

i.fa {
  display: inline-block;
  padding: 0 10px;
}

.transparent {
  border: none;
  outline: none;
  background: transparent;
}

.modal-dialog-actions {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 20px !important;
}

/* Table Actions */
.table-action-delete {
  color: #ca1829;
}

/* MATERIAL INPUTS */
/* We set style based on our palette */
.mdc-text-field--filled .mdc-line-ripple::after {
  border-bottom-color: #40034e !important;
}

.mat-mdc-form-field:not(.mat-form-field-invalid) mat-label {
  color: #40034e !important;
}

/* MATERIAL BUTTONS */
/* We set style based on our palette */
button.mat-primary, a.mat-primary {
  color: #ffffff !important;
  background-color: #40034e !important;
  cursor: pointer !important;
}

button.mat-accent, a.mat-accent {
  color: #ffffff;
  background-color: #8d37ee;
  cursor: pointer !important;
}

button.mat-warn, a.mat-warn {
  color: #a99700;
  background-color: #f6f2bd;
  cursor: pointer !important;
}

button.mat-disabled, a.mat-disabled {
  color: #333333;
  background-color: #dddddd;
  cursor: pointer !important;
}

.mat-mdc-button {
  display: inline-flex;
  cursor: pointer !important;
}

/* MATERIAL SNACKBAR */
/* We set style based on our palette */
.mat-mdc-snack-bar-container .mdc-snackbar__surface {
  background-color: transparent !important;
}
.mat-mdc-snack-bar-container.app-notification-info {
  background-color: #07CCD6 !important;
}
.mat-mdc-snack-bar-container.app-notification-info div, .mat-mdc-snack-bar-container.app-notification-info button {
  color: #0072bb !important;
}
.mat-mdc-snack-bar-container.app-notification-warning {
  background-color: #f6f2bd;
}
.mat-mdc-snack-bar-container.app-notification-warning div, .mat-mdc-snack-bar-container.app-notification-warning button {
  color: #a99700 !important;
}
.mat-mdc-snack-bar-container.app-notification-error {
  background-color: #ca1829;
}
.mat-mdc-snack-bar-container.app-notification-error div, .mat-mdc-snack-bar-container.app-notification-error button {
  color: #ca1829 !important;
}
.mat-mdc-snack-bar-container.app-notification-success {
  background-color: #a9e2da;
}
.mat-mdc-snack-bar-container.app-notification-success div, .mat-mdc-snack-bar-container.app-notification-success button {
  color: #006e57 !important;
}

/* MATERIAL SPINNER */
/* We set the style for the material spinner indicator */
mat-spinner {
  position: absolute !important;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.mat-mdc-progress-spinner circle, .mat-mdc-spinner circle {
  stroke: #40034e !important;
}

/* MATERIAL LIST */
/* We set color for all list items even inside custom select components */
.mdc-list-item--selected .mdc-list-item__primary-text,
.mdc-list-item--activated .mdc-list-item__primary-text {
  color: #40034e !important;
}

/* Material Menu Panel */
/* We set the top and right margin from the origin point */
.mat-mdc-menu-panel:not(.table-action-menu) {
  margin-top: 15px !important;
  margin-right: 15px !important;
}

/* MATERIAL LIST */
/* We set color for all list items even inside custom select components */
.mdc-list-item--selected .mdc-list-item__primary-text,
.mdc-list-item--activated .mdc-list-item__primary-text {
  color: #40034e !important;
}

/*Material menu panel item*/
/*Added padding and increased font size*/
.mat-mdc-menu-item {
  padding-right: 20px !important;
  padding-left: 20px !important;
}
.mat-mdc-menu-item i, .mat-mdc-menu-item span {
  font-size: 17.15px !important;
}

/*Material menu panel*/
/*make panel appear from the right*/
.mat-mdc-menu-panel:not(.table-action-menu) + .cdk-overlay-connected-position-bounding-box {
  align-items: flex-end !important;
}

/*Material input icon*/
/*make suffix not hug right side of input*/
.mat-mdc-form-field-icon-suffix {
  padding-right: 10px !important;
}

.mat-mdc-select-value-text {
  display: flex !important;
}

.create-modify-container {
  padding: 20px;
  width: 800px;
  height: 90vh;
  overflow: auto;
}
.create-modify-container .create-modify-button {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}
.create-modify-container .create-modify-button button {
  margin: 0 5px;
}

form {
  overflow-y: hidden;
}

.col-2 {
  display: flex;
  flex-direction: row;
  align-content: center;
  justify-content: space-between;
}
.col-2 .sided {
  width: 48%;
}
.col-2 .sided:first-child {
  margin: 0 1% 0 0;
}
.col-2 .sided:last-child {
  margin: 0 0 0 1%;
}

.col-3 {
  display: flex;
  flex-direction: row;
  align-content: center;
  justify-content: space-between;
}
.col-3 .sided {
  width: 30%;
}
.col-3 .sided:first-child {
  margin: 0 1% 0 0;
}
.col-3 .sided:last-child {
  margin: 0 0 0 1%;
}

.col-4 {
  display: flex;
  flex-direction: row;
  align-content: center;
  justify-content: space-between;
}
.col-4 .sided {
  width: 23%;
  margin: 0 1% 0 1%;
}
.col-4 .sided:first-child {
  margin: 0 1% 0 0;
}
.col-4 .sided:last-child {
  margin: 0 0 0 1%;
}

.col-50-20-30 {
  display: flex;
  flex-direction: row;
  align-content: center;
  justify-content: space-between;
}
.col-50-20-30 .sided:first-child {
  width: 48%;
  margin: 0 2% 0 0;
}
.col-50-20-30 .sided:nth-child(2) {
  width: 16%;
  margin: 0 2% 0 2%;
}
.col-50-20-30 .sided:last-child {
  width: 28%;
  margin: 0 0 0 2%;
}

.col-40-30-30 {
  display: flex;
  flex-direction: row;
  align-content: center;
  justify-content: space-between;
}
.col-40-30-30 .sided:first-child {
  width: 38%;
  margin: 0 2% 0 0;
}
.col-40-30-30 .sided:nth-child(2) {
  width: 26%;
  margin: 0 2% 0 2%;
}
.col-40-30-30 .sided:last-child {
  width: 28%;
  margin: 0 0 0 2%;
}

::ng-deep .readonly .mdc-text-field--filled:not(.mdc-text-field--disabled) {
  background-color: #ccc !important;
}

.table-header-container {
  display: flex;
  width: 100%;
  justify-content: space-between;
}
.table-header-container .table-header-text {
  display: flex;
  flex-direction: row;
  align-items: baseline;
}
.table-header-container .table-header-text i {
  font-size: 20px;
  padding-right: 10px;
}
.table-header-container .table-header-text .back-button-container {
  margin-right: 60px;
}

.table-search-form-container {
  display: flex;
  flex-direction: row-reverse !important;
  width: 50%;
}

.align-right {
  text-align: right;
}
.align-right button {
  display: inline-block;
}

.card-detail p {
  display: flex;
  flex-direction: row;
}
.card-detail i {
  display: flex;
  justify-content: center;
  width: 30px;
  margin-right: 10px;
}

.detail-table {
  margin-top: 20px;
}

.button-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}
.button-container button {
  margin: 10px;
}

.eye-toggle-container {
  padding: 8px;
}

.v-centered-row {
  display: flex;
  align-items: center;
}

.space-between {
  display: flex;
  justify-content: space-between;
}

.justify-end {
  display: flex;
  justify-content: flex-end;
}