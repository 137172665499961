.table-header-container {
  display: flex;
  width: 100%;
  justify-content: space-between;

  .table-header-text{
    display: flex;
    flex-direction: row;
    align-items: baseline;
    i {
      font-size: 20px;
      padding-right: 10px;
    }
    .back-button-container {
      margin-right: 60px;
    }
  }
}

.table-search-form-container {
  display: flex;
  flex-direction: row-reverse!important;
  width: 50%;
}

.align-right{
  text-align: right;
  button {
    display: inline-block;
  }
}
